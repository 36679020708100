import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { userAtom, authAtom, tokenAtom } from '../../core/config/atoms';

import { motion } from "framer-motion";
import Lottie from 'lottie-react';
import { Dialog } from '@headlessui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import TopBar from '../../core/widgets/ui/TopBar';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceAuth from '../../services/serviceAuth';

export default function PanelSettings(props) {

    const [userData, setUserData] = useRecoilState(userAtom);
    const [token] = useRecoilState(tokenAtom);

    const [, setAuthStatus] = useRecoilState(authAtom);

    const [isOpenEmail, setIsOpenEmail] = useState(false);
    const [isOpenPassword, setIsOpenPassword] = useState(false);
    const [isOpenUsername, setIsOpenUsername] = useState(false);
    const [isOpenDisable, setIsOpenDisable] = useState(false);

    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const authService = new ServiceAuth();

    var A = { email: '', password: '' };
    var C = { opass: '', npass: '', cpass: '' };
    var D = { msg: '' };
    var E = { fname: '', mname: '', lname: '', password: '' };

    const formVSchemaA = Yup.object().shape({
        email: Yup.string().email('Please enter valid email address').required('This information is required'),
        password: Yup.string().required('This information is required'),
    });

    const formVSchemaC = Yup.object().shape({
        opass: Yup.string().required('This information is required'),
        npass: Yup.string().required('This information is required'),
        cpass: Yup.string().required('This information is required'),
    });

    const formVSchemaD = Yup.object().shape({
        msg: Yup.string().required('This information is required'),
    });

    const formVSchemaE = Yup.object().shape({
        fname: Yup.string().required('This information is required'),
        mname: Yup.string().required('This information is required'),
        lname: Yup.string().required('This information is required'),
        password: Yup.string().required('This information is required'),
    });

    const getInitValues = () => {
        if (isOpenEmail) {
            return A;
        }
        if (isOpenPassword) {
            return C;
        }
        if (isOpenDisable) {
            return D;
        }
        if (isOpenUsername) {
            return E;
        }
        return A;
    }

    const getInitSchema = () => {
        if (isOpenEmail) {
            return formVSchemaA;
        }
        if (isOpenPassword) {
            return formVSchemaC;
        }
        if (isOpenDisable) {
            return formVSchemaD;
        }
        if (isOpenUsername) {
            return formVSchemaE;
        }
        return formVSchemaA;
    }

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm, setErrors } = useFormik({
        initialValues: getInitValues(),
        validationSchema: getInitSchema(),
        enableReinitialize: true,
        onSubmit: values => {
            if (isOpenDisable) {
                if (values.msg !== "DISABLE") {
                    setErrors({ ...errors, msg: 'Please enter valid value.' });
                } else {
                    setSubmit(true);
                    let body = {
                        uid: userData._id
                    };
                    authService.deactiveUser(body, token).then((res) => {
                        if (res.status) {
                            resetForm();
                            setIsOpenDisable(false);
                            logout();
                        } else {
                            toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        }
                        setSubmit(false);
                    });
                }
            }
            if (isOpenEmail) {
                setSubmit(true);
                let body = {
                    uid: userData._id,
                    nemail: values.email,
                    password: values.password
                };
                authService.updateEmail(body, token).then((res) => {
                    if (res.status) {
                        resetForm();
                        setIsOpenEmail(false);
                        setUserData(res.data);
                        toast.success('Email address updated.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    } else {
                        toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                    setSubmit(false);
                });
            }
            if (isOpenPassword) {
                if (values.npass != values.cpass) {
                    setErrors({ ...errors, cpass: 'Password did not match.' });
                    return;
                }
                setSubmit(true);
                let body = {
                    uid: userData._id,
                    opass: values.opass,
                    npass: values.npass
                };
                authService.updatePassword(body, token).then((res) => {
                    if (res.status) {
                        resetForm();
                        setIsOpenPassword(false);
                        logout();
                        toast.success('Password updated.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    } else {
                        toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                    setSubmit(false);
                });
            }
            if (isOpenUsername) {
                setSubmit(true);
                let body = {
                    uid: userData._id,
                    fname: values.fname,
                    mname: values.mname,
                    lname: values.lname,
                    password: values.password
                };
                authService.updateUsername(body, token).then((res) => {
                    if (res.status) {
                        resetForm();
                        setIsOpenUsername(false);
                        setUserData(res.data);
                        toast.success('Phone number updated.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    } else {
                        toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                    setSubmit(false);
                });
            }
        }
    });

    const logout = () => {
        setAuthStatus('default');
        setUserData({});
        navigate('/');
    }

    return (
        <div className="w-full h-full py-3 px-3">
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10">
                        <h2 class="text-2xl font-medium truncate ml-2 text-gray-900">
                            <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> User Profile
                        </h2>
                        <TopBar />
                    </div>
                    <hr className='mt-2' />
                    <div className="col-span-12 mt-4">
                        <div className="intro-y w-full shadow-lg">
                            <div className='h-28 bg-prime rounded-t-lg relative'>
                                <div className='bg-prime w-20 h-20 rounded-full flex items-center justify-center border-4 border-white absolute' style={{ bottom: '-3rem', left: '1rem' }}>
                                    <h3 className='text-white text-xl uppercase'>{userData.user.fname[0]}{userData.user.lname[0]}</h3>
                                    <div></div>
                                </div>
                            </div>
                            <div className='pb-4 px-4 mb-4 rounded-b-lg'>
                                <div className='flex items-center justify-between mt-1 mb-4'>
                                    <div className='text-2xl font-bold ml-24'>{userData.user.fname} {userData.user.lname}</div>
                                </div>
                                <div className='bg-gray-00 mt-2 p-4 rounded'>
                                    <div className='flex items-center justify-between'>
                                        <div>
                                            <div className='text-xs text-gray-600'>USERNAME</div>
                                            <div className='text-lg text-black'>{userData.user.fname} {userData.user.mname} {userData.user.lname}</div>
                                        </div>
                                        <div className='cursor-pointer bg-prime text-sm font-serif py-2 px-4 rounded-md text-white' onClick={() => {
                                            setIsOpenUsername(true);
                                        }}>Edit</div>
                                    </div>
                                    {/* <div className='flex items-center justify-between mt-3'>
                                        <div>
                                            <div className='text-xs text-gray-600'>Email</div>
                                            <div className='text-base text-black'>{userData.user.email}</div>
                                        </div>
                                        <div className='cursor-pointer bg-prime text-sm font-serif py-2 px-4 rounded-md text-white' onClick={() => {
                                            setIsOpenEmail(true);
                                        }}>Edit</div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr className='my-4' />
                    <h2 className='text-2xl font-medium truncate'>Account Removal</h2>
                    <h2 className='text-base truncate'>Disabling this account means you can recover it after admin permission only.</h2>
                    <div className='flex'>
                        <div className='bg-red-400 cursor-pointer text-base font-serif mt-4 w-40 rounded px-2 py-2 text-white text-center' onClick={() => {
                            setIsOpenDisable(true);
                        }}>
                            Delete Account
                        </div>
                        <div className='ml-4 border-2 cursor-pointer border-red-400 text-red-400 text-base font-serif mt-4 w-40 rounded px-2 py-2 text-center' onClick={() => { logout() }}>
                            Logout
                        </div>
                    </div>
                </div>
                <div className='col-span-12 h-screen w-full'>
                    <div className="w-full h-full p-2 overflow-hidden">
                        <Dialog open={isOpenEmail} onClose={() => {
                            setIsOpenEmail(false);
                            resetForm();
                        }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                                <div className='flex items-center justify-between'>
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        Update Profile &nbsp;
                                    </h3>
                                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                        setIsOpenEmail(false);
                                        resetForm();
                                    }}>
                                        <i className="las la-times text-sm text-black"></i>
                                    </span>
                                </div>
                                <hr className='mt-2' />
                                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Your email address<sup className="text-red-600">*</sup></label>
                                        <input type="email" id="email" value={values.email} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. jhon.deo@gmail.com" onChange={handleChange} />
                                        {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Your current password<sup className="text-red-600">*</sup></label>
                                        <input type="password" id="password" value={values.password} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                        {(errors.password && touched.password) && <p className='text-xs text-red-400 mt-1'>{errors.password}</p>}
                                    </div>
                                    <div className='flex items-end'>
                                        <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "Update"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Dialog>
                        <Dialog open={isOpenPassword} onClose={() => {
                            setIsOpenPassword(false);
                            resetForm();
                        }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                                <div className='flex items-center justify-between'>
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        Update Profile &nbsp;
                                    </h3>
                                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                        setIsOpenPassword(false);
                                        resetForm();
                                    }}>
                                        <i className="las la-times text-sm text-black"></i>
                                    </span>
                                </div>
                                <hr className='mt-2' />
                                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Your current password<sup className="text-red-600">*</sup></label>
                                        <input type="password" id="opass" value={values.opass} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                        {(errors.opass && touched.opass) && <p className='text-xs text-red-400 mt-1'>{errors.opass}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Your new password<sup className="text-red-600">*</sup></label>
                                        <input type="password" id="npass" value={values.npass} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                        {(errors.npass && touched.npass) && <p className='text-xs text-red-400 mt-1'>{errors.npass}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Confirm password<sup className="text-red-600">*</sup></label>
                                        <input type="password" id="cpass" value={values.cpass} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                        {(errors.cpass && touched.cpass) && <p className='text-xs text-red-400 mt-1'>{errors.cpass}</p>}
                                    </div>
                                    <div className='flex items-end'>
                                        <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "Update"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Dialog>
                        <Dialog open={isOpenDisable} onClose={() => {
                            setIsOpenDisable(false);
                            resetForm();
                        }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                                <div className='flex items-center justify-between'>
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        Update Profile &nbsp;
                                    </h3>
                                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                        setIsOpenDisable(false);
                                        resetForm();
                                    }}>
                                        <i className="las la-times text-sm text-black"></i>
                                    </span>
                                </div>
                                <hr className='mt-2' />
                                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm text-gray-900">To disable account type the word <span className='font-bold'>DISABLE</span><sup className="text-red-600">*</sup></label>
                                        <input type="text" id="msg" value={values.msg} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded block w-full p-2.5 focus:outline-none focus:shadow focus:shadow-blue-500/50 focus:border-blue-400 uppercase" maxLength={7} placeholder="DISABLE" onChange={handleChange} />
                                        {(errors.msg && touched.msg) && <p className='text-xs text-red-400 mt-1'>{errors.msg}</p>}
                                    </div>
                                    <div className='flex items-end'>
                                        <button type="submit" className={`text-white focus:ring-4 focus:outline-none font-medium rounded text-sm w-full sm:w-auto px-5 py-2.5 text-center bg-red-600 hover:bg-red-800`} disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "Disable"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Dialog>
                        <Dialog open={isOpenUsername} onClose={() => {
                            setIsOpenUsername(false);
                            resetForm();
                        }} className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                                <div className='flex items-center justify-between'>
                                    <h3 className="text-xl font-semibold text-gray-900">
                                        Update Profile &nbsp;
                                    </h3>
                                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                                        setIsOpenUsername(false);
                                        resetForm();
                                    }}>
                                        <i className="las la-times text-sm text-black"></i>
                                    </span>
                                </div>
                                <hr className='mt-2' />
                                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} noValidate="" autoComplete='off'>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">First Name<sup className="text-red-600">*</sup></label>
                                        <input type="text" id="fname" value={values.fname} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. Jhon" onChange={handleChange} />
                                        {(errors.fname && touched.fname) && <p className='text-xs text-red-400 mt-1'>{errors.fname}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Maiden Name<sup className="text-red-600">*</sup></label>
                                        <input type="text" id="mname" value={values.mname} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. Harward" onChange={handleChange} />
                                        {(errors.mname && touched.mname) && <p className='text-xs text-red-400 mt-1'>{errors.mname}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Last Name<sup className="text-red-600">*</sup></label>
                                        <input type="text" id="lname" value={values.lname} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. Deo" onChange={handleChange} />
                                        {(errors.lname && touched.lname) && <p className='text-xs text-red-400 mt-1'>{errors.lname}</p>}
                                    </div>
                                    <div className="mb-6">
                                        <label className="block mb-2 text-sm font-medium text-gray-600">Your current password<sup className="text-red-600">*</sup></label>
                                        <input type="password" id="password" value={values.password} className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. xxxxxxxxxxx" onChange={handleChange} />
                                        {(errors.password && touched.password) && <p className='text-xs text-red-400 mt-1'>{errors.password}</p>}
                                    </div>
                                    <div className='flex items-end'>
                                        <button type="submit" className="text-white bg-prime hover:bg-primeLight focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                            {!submit && "Update"}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </Dialog>
                        <div className="m-2">

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
