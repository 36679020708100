import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";
import { Send, Video, Mic, MicOff } from 'lucide-react';

import { useRecoilState } from 'recoil';
import { userAtom, attandanceAtom, tokenAtom } from '../../core/config/atoms';

import Lottie from 'lottie-react';
import moment from 'moment';
import { Device } from 'mediasoup-client';
import { AnimatePresence, motion } from "framer-motion";
import { toast } from 'react-toastify';

import TopBar from '../../core/widgets/ui/TopBar';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import socket from '../../core/config/socket';

export default function PanelMain(props) {

    const [userData] = useRecoilState(userAtom);
    const [token] = useRecoilState(tokenAtom);

    const [mDevice, setMDevice] = useState(null);
    const [prdTransport, setProducerTransport] = useState(null);
    const [conTransport, setConsumerTransport] = useState(null);

    const [prdAudio, setPrdAudio] = useState(null);
    const [prdVideo, setPrdVideo] = useState(null);

    const [crdAudio, setCrdAudio] = useState(null);
    const [crdVideo, setCrdVideo] = useState(null);

    const [queue, setQueue] = useState([]);

    const [roomid, setRoomID] = useState('');

    const [isStarted, setIsStarted] = useState(false);
    const [isVid, setIsVid] = useState(true);
    const [isAudio, setIsAudio] = useState(true);
    const [submit, setSubmit] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [isJoin, setIsJoin] = useState(false);

    const localVideoRef = useRef();
    const remoteVideoRef = useRef();
    const chatRef = useRef('');

    const [chats, setChats] = useState([]);

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const initMediaSoup = async (rtpCapabilities) => {
        try {
            let dev = new Device();
            await dev.load({
                routerRtpCapabilities: rtpCapabilities
            });
            setMDevice(dev);
        } catch (error) {
            console.log(error);
            if (error.name === 'UnsupportedError') {
                console.warn('browser not supported');
            }
        }
    }

    const joinTicket = async (id, status) => {
        if (status === "Open") {
            setRoomID(id);
            socket.emit('joinMeet', { roomid: id, iid: userData._id, name: `${userData.user.fname} ${userData.user.lname}` }, (rtpCapabilities) => {
                initMediaSoup(rtpCapabilities);
            });
        } else {
            toast.warn("Ticked already been assinged to an agent.", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        }
    }

    const connectToMeet = () => {
        setIsStarted(true);
        initTransports(roomid);
    }

    const initTransports = async (rmid) => {
        // init producerTransport
        {
            socket.emit('createWebRtcTransport', { roomid: rmid }, (paramsA) => {
                if (paramsA.error) {
                    console.error(paramsA.error)
                    return
                }

                let producerTransport = mDevice.createSendTransport(paramsA);

                producerTransport.on('connect', async function ({ dtlsParameters }, callback, errback) {
                    socket.emit('connectTransport', { roomid: rmid, transport_id: paramsA.id, dtlsParameters }, (res) => {
                        console.log(res);
                    });
                    callback();
                }.bind(this));

                producerTransport.on('produce', async function ({ kind, rtpParameters }, callback, errback) {
                    await socket.emit('produce', {
                        roomid: rmid,
                        producerTransportId: producerTransport.id,
                        kind,
                        rtpParameters
                    }, (res) => {
                        callback({
                            id: res.producer_id
                        });
                    });
                }.bind(this));

                producerTransport.on('connectionstatechange', function (state) {
                    console.log(state);
                    switch (state) {
                        case 'failed':
                            producerTransport.close();
                            break;
                        default:
                            break;
                    }
                }.bind(this));

                producerTransport.on('failed', function (error) {
                    console.error('Producer transport failed:', error);
                }.bind(this));

                setProducerTransport(producerTransport);

                // init consumerTransport
                {
                    socket.emit('createWebRtcTransport', { roomid: rmid }, (paramsB) => {
                        if (paramsB.error) {
                            console.error(paramsB.error)
                            return
                        }

                        let consumerTransport = mDevice.createRecvTransport(paramsB);
                        consumerTransport.on('connect', function ({ dtlsParameters }, callback, errback) {
                            socket.emit('connectTransport', { roomid: rmid, transport_id: consumerTransport.id, dtlsParameters }, (res) => {
                                console.log(res);
                            });
                            callback();
                        }.bind(this));

                        consumerTransport.on('connectionstatechange', async function (state) {
                            console.log(state);
                            switch (state) {
                                case 'failed':
                                    consumerTransport.close();
                                    break;
                                default:
                                    break;
                            }
                        }.bind(this));

                        consumerTransport.on('failed', function (error) {
                            console.error('Consumer transport failed:', error);
                        }.bind(this));

                        setConsumerTransport(consumerTransport);

                        socket.emit('getProducers', { roomid: rmid });
                    });
                }
            });
        }
    }

    const sendChat = () => {
        const message = chatRef.current.value;
        if (message !== "") {
            let chat = {
                name: `${userData.user.fname} ${userData.user.lname}`,
                message: message,
                time: moment(Date.now()).toISOString()
            }
            socket.emit('sendMessage', { roomid: roomid, chat });
            chatRef.current.value = "";
        }
    }

    const connectAudio = async () => {
        const track = localVideoRef.current.srcObject.getAudioTracks()[0];
        const params = { track };
        let producer = await prdTransport.produce(params);

        producer.on('trackended', () => {
            const producer_id = prdAudio.id;
            socket.emit('producerClosed', {
                producer_id
            });
            prdAudio.close();
            setPrdAudio(null);
        });

        producer.on('transportclose', () => {
            console.log('Producer transport close')
            setPrdAudio(null);
        });

        producer.on('close', () => {
            console.log('Closing producer')
            setPrdAudio(null);
        });

        setPrdAudio(producer);
    }

    const connectVideo = async () => {
        const track = localVideoRef.current.srcObject.getVideoTracks()[0];
        const params = { track };
        let producer = await prdTransport.produce(params);

        producer.on('trackended', () => {
            const producer_id = prdVideo.id;
            socket.emit('producerClosed', {
                producer_id
            });
            prdVideo.close();
            setPrdVideo(null);
        });

        producer.on('transportclose', () => {
            console.log('Producer transport close')
            setPrdVideo(null);
        });

        producer.on('close', () => {
            console.log('Closing producer')
            setPrdVideo(null);
        });

        setPrdVideo(producer);
    }

    const connectSendTransport = () => {
        connectAudio();
        connectVideo();
    }

    const getConsumeStream = async (producerId) => {
        const { rtpCapabilities } = mDevice;
        socket.emit('consume-agent', { roomid, rtpCapabilities, consumerTransportId: conTransport.id, producerId }, async (param) => {
            const { id, kind, rtpParameters } = param;
            let codecOptions = {}
            const consumer = await conTransport.consume({
                id,
                producerId,
                kind,
                rtpParameters,
                codecOptions
            });

            if (remoteVideoRef.current.srcObject === null) {
                const stream = new MediaStream();
                stream.addTrack(consumer.track);
                remoteVideoRef.current.srcObject = stream;
            } else {
                remoteVideoRef.current.srcObject.addTrack(consumer.track);
            }

            if (kind === 'audio') {
                consumer.on('trackended', function () {
                    setCrdAudio(null);
                }.bind(this));

                consumer.on('transportclose', function () {
                    setCrdAudio(null);
                }.bind(this));

                setCrdAudio(consumer);
            } else {
                consumer.on('trackended', function () {
                    setCrdVideo(null);
                }.bind(this));

                consumer.on('transportclose', function () {
                    setCrdVideo(null);
                }.bind(this));

                setCrdVideo(consumer);
            }

            setIsJoin(true);
        });
    }

    const toggleVideo = () => {
        if (prdVideo) {
            if (isVid) {
                prdVideo.pause();
            } else {
                prdVideo.resume();
            }
            setIsVid(!isVid);
        }
    }

    const toggleAudio = () => {
        if (prdAudio) {
            if (isAudio) {
                prdAudio.pause();
            } else {
                prdAudio.resume();
            }
        }
    }

    const initLocalVideo = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        localVideoRef.current.srcObject = stream;
    }

    useEffect(() => {
        if (prdTransport !== null) {
            connectSendTransport();
        }
    }, [prdTransport]);

    useEffect(() => {
        if (conTransport !== null && mDevice !== null) {
            socket.on('newProducers', async function (data) {
                if (data.length !== 0) {
                    for (let { producer_id } of data) {
                        console.log(producer_id);
                        await getConsumeStream(producer_id);
                    }
                }
            }.bind(this));
        }
    }, [conTransport, mDevice]);

    useEffect(() => {
        if (mDevice !== null && roomid !== '') {
            connectToMeet();
        }
    }, [mDevice, roomid]);

    useEffect(() => {
        initLocalVideo();
        socket.emit('getQueue', {}, (que) => {
            setQueue(que);
        });
        socket.on('updateQueue', (que) => {
            setQueue(que);
        });
        socket.on('recvChat', (chat) => {
            let chatList = [...chats];
            chatList.push(chat);
            setChats(chatList);
        });
    }, [chats]);

    return (
        <div className='w-full h-screen overflow-hidden flex'>
            <div className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10">
                            <h2 class="text-2xl font-medium truncate ml-2 text-gray-900">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { setIsOpen(!isOpen) }}></motion.i> {userData.user.fname} {userData.user.lname}
                            </h2>
                            <TopBar />
                        </div>
                        <hr className='mt-2' />
                    </div>
                    <div className="col-span-12 mt-2 h-screen">
                        <div className='w-full h-full px-2'>
                            <div className='h-[76%] w-full grid grid-cols-12 gap-4 my-2 md:my-4'>
                                <div className={`col-span-12 h-full bg-gray-50 rounded-lg flex flex-col`}>
                                    <div className='w-full h-[90%] border-2 border-prime rounded-md'>
                                        <div className='w-full h-full relative'>
                                            <video ref={remoteVideoRef} autoPlay className='rounded-md object-fill absolute inset-0 shadow w-full h-full' />
                                            <video ref={localVideoRef} autoPlay playsInline muted className={`rounded-md object-fill absolute inset-0 ${isStarted ? 'w-[20%] h-[20%] top-[74%] left-[77%]' : 'w-full h-full'}`} />
                                        </div>
                                    </div>
                                    <div className='w-full h-[10%] flex items-center'>
                                        <div className={`w-10 h-10 cursor-pointer rounded-md mx-2 text-white flex items-center justify-center ${isVid ? 'bg-red-600' : 'bg-gray-800'}`} onClick={() => {
                                            toggleVideo();
                                        }}>
                                            <Video size={18} />
                                        </div>
                                        <div className={`w-10 h-10 cursor-pointer rounded-md mx-2 text-white flex items-center justify-center ${isAudio ? 'bg-red-600' : 'bg-gray-800'}`} onClick={() => {
                                            toggleAudio();
                                        }}>
                                            {isAudio && <MicOff size={18} />}
                                            {!isAudio && <Mic size={18} />}
                                        </div>
                                        <div className='flex-grow'></div>
                                        {
                                            isJoin && <div className='bg-red-600 cursor-pointer h-10 w-[160px] mr-2 text-white text-sm font-medium flex items-center justify-center rounded-md'>
                                                END CALL
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
