import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceAuth {
    lookupService = new APIServiceLookUp();

    async userRegistertion(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/cutomerRegister`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async userLogin(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/cutomerLogin`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async userVerify(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}auth/cutomerVerify`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }
}