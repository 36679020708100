import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { authAtom, phoneAtom } from '../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

import animBtnLoading from '../assets/anim/anim-btnLoading.json';

import ServiceAuth from '../services/serviceAuth';
import ServiceOption from '../services/serviceOption';

import bgLogin from '../assets/image/bglogin.png';

export default function PageRegister() {

    const [authStatus] = useRecoilState(authAtom);
    const [, setPhone] = useRecoilState(phoneAtom);

    const [contryOptions, setCountryOptions] = useState([]);
    const [conditionOptions, setConditionOptions] = useState([]);

    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const authService = new ServiceAuth();
    const optionService = new ServiceOption();

    const userTypes = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Other', label: 'Other' },
    ];

    const formVSchema = Yup.object().shape({
        fullName: Yup.string().required('This information is required'),
        email: Yup.string().email('Please enter valid email address'),
        phone: Yup.string().required('This information is required').matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
        gender: Yup.string().required('This information is required'),
        country: Yup.string().required('This information is required'),
        condition: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            fullName: '',
            email: '',
            phone: '',
            gender: '',
            country: '',
            condition: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let names = values.fullName.split(' ');
            let body = {
                "fname": names[0],
                "mname": names.length > 2 ? names[1] : "NA",
                "lname": names.length > 2 ? names[2] : names.length > 1 ? names[1] : "NA",
                "email": values.email,
                "phone": values.phone,
                "gender": values.gender,
                "country": values.country,
                "condition": values.condition,
            }
            authService.userRegistertion(body, '').then((res) => {
                if (res.status) {
                    toast.success("Registration successful!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    setPhone(values.phone);
                    gotoOTP();
                } else {
                    toast.error(res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                handleReset();
                setSubmit(false);
            });
        }
    });

    const gotoOTP = () => {
        navigate('/verify');
    }

    const gotoLogin = () => {
        navigate('/');
    }

    const loadData = () => {
        (async function () {
            var cuRes = await optionService.getCountryOptions({}, '');
            setCountryOptions(cuRes.data);

            var coRes = await optionService.getConditionOptions({}, '');
            setConditionOptions(coRes.data);
        })();
    }

    useEffect(() => {
        loadData();
        if (authStatus === 'verified') {
            navigate('/home/main');
        }
    }, [authStatus, navigate]);

    return (
        <>
            <div className="w-screen h-screen overflow-hidden relative">
                <div className="xl:grid xl:grid-cols-12 xl:gap-0">
                    <div className="absolute xl:relative w-full xl:col-span-5">
                        <div className="h-screen flex flex-col items-center justify-center xl:bg-blue-50">
                            <div className="shadow-lg rounded-xl px-4 xl:px-10 py-20 xl:py-10 bg-white mt-6 mx-2 max-w-md md:w-auth w-11/12">
                                <h1 className="text-2xl text-black font-serif font-medium">Sign Up!</h1>
                                <h2 className="text-sm text-gray-400 font-serif">We`re so excited to take you onboard!</h2>
                                <form className="mt-8" onSubmit={handleSubmit}>
                                    <div className="flex flex-col">
                                        <label className="text-sm text-gray-900 font-serif">Full Name
                                            <sup className="text-red-600">*</sup>
                                        </label>
                                        <input type="off" placeholder="i.e. Rajesh Kamal Hasan" onChange={handleChange} value={values.fullName} id="fullName" className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" required autoComplete="off" />
                                        {(errors.fullName && touched.fullName) && <p className='text-xs text-red-400 mt-1'>{errors.fullName}</p>}
                                    </div>
                                    <div className="flex flex-col mt-3">
                                        <label className="text-sm text-gray-900 font-serif">Phone number
                                            <sup className="text-red-600">*</sup>
                                        </label>
                                        <input type="off" placeholder="i.e. 123456789" onChange={handleChange} value={values.phone} id="phone" className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" required autoComplete="off" />
                                        {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                    </div>
                                    <div className="flex flex-col mt-3">
                                        <label className="text-sm text-gray-900 font-serif">Email Address &nbsp;
                                            <sub className="text-gray-400">(optional)</sub>
                                        </label>
                                        <input type="off" placeholder="i.e. ramesh@gmail.com" onChange={handleChange} value={values.email} id="email" className="w-full h-11 rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1" required autoComplete="off" />
                                        {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                    </div>
                                    <div className="flex flex-col mt-3">
                                        <label className="text-sm text-gray-900 font-serif">Gender
                                            <sup className="text-red-600">*</sup>
                                        </label>
                                        <select id="gender" onChange={handleChange} value={values.gender} className="appearance-none w-full rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1">
                                            <option value="" disabled="" selected="selected">Select</option>
                                            {
                                                userTypes.length !== 0 && userTypes.map((item) => {
                                                    return <option value={item.value} selected={values.uType == item.value}>{item.label}</option>
                                                })
                                            }
                                        </select>
                                        {(errors.gender && touched.gender) && <p className='text-xs text-red-400 mt-1'>{errors.gender}</p>}
                                    </div>
                                    <div className="flex flex-col mt-3">
                                        <label className="text-sm text-gray-900 font-serif">Country
                                            <sup className="text-red-600">*</sup>
                                        </label>
                                        <select id="country" onChange={handleChange} value={values.country} className="appearance-none w-full rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1">
                                            <option value="" disabled="" selected="selected">Select</option>
                                            {
                                                contryOptions.length !== 0 && contryOptions.map((item) => {
                                                    return <option value={item.value}>{item.label}</option>
                                                })
                                            }
                                        </select>
                                        {(errors.country && touched.country) && <p className='text-xs text-red-400 mt-1'>{errors.country}</p>}
                                    </div>
                                    <div className="flex flex-col mt-3">
                                        <label className="text-sm text-gray-900 font-serif">Condition
                                            <sup className="text-red-600">*</sup>
                                        </label>
                                        <select id="condition" onChange={handleChange} value={values.condition} className="appearance-none w-full rounded bg-blue-50 px-4 py-2 text-sm font-sans mt-1">
                                            <option value="" disabled="" selected="selected">Select</option>
                                            {
                                                conditionOptions.length !== 0 && conditionOptions.map((item) => {
                                                    return <option value={item.value}>{item.label}</option>
                                                })
                                            }
                                        </select>
                                        {(errors.condition && touched.condition) && <p className='text-xs text-red-400 mt-1'>{errors.condition}</p>}
                                    </div>
                                    <button type="submit" className="mt-8 text-xs font-bold text-white rounded bg-prime hover:bg-primeLight w-full h-10 font-mono flex items-center justify-center" disabled={submit}>
                                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" />}
                                        {!submit && "REGISTER"}
                                    </button>
                                    <div className='flex items-center mt-4'>
                                        <div className='w-[80px] h-[1px] bg-gray-600'></div>
                                        <p className='text-sm text-center mx-auto font-medium'>Already have an account</p>
                                        <div className='w-[80px] h-[1px] bg-gray-600'></div>
                                    </div>
                                    <button type="button" className="mt-4 text-xs font-bold text-white rounded bg-prime hover:bg-primeLight w-full h-10 font-mono flex items-center justify-center" onClick={() => {
                                        gotoLogin();
                                    }}>
                                        LOGIN
                                    </button>
                                </form>
                                <div id="captcha"></div>
                                <div className="flex-grow"></div>
                                <p className="text-sm text-gray-400 font-serif text-center mt-16">&copy; 2024 India Signing Hands Private Limited.</p>
                            </div>
                        </div>
                    </div>
                    <div className="h-screen xl:col-span-7 xl:flex items-center">
                        <div className="w-full h-full flex items-start lg:items-center justify-center">
                            <img src={bgLogin} alt="brand logo" className="login background" />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
