import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceOption {
    lookupService = new APIServiceLookUp();

    async getCountryOptions(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}country/getCountryOptions`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getConditionOptions(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}disbilitie/getDisbilitieOptions`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}